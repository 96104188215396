.part-banner {
  min-height: 272px;
  overflow: hidden;
  color: white;

  .container {
    min-height: 272px;
    display: flex;
    align-items: center;
  }

  .banner-image {
    overflow: hidden;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      width: 100vw;
      left: 50%;
      margin-left: -50vw;
    }
  }

  .subtitle {
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    font-size: $header-font-size;
  }

  h1 {
    line-height: 1;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }

  &.opacity-0 {
    opacity: 0;
  }

  &.opacity-1 {
    opacity: 1;
    transition: all 0.2s;
  }

  &.fact-page-banner {
    background-color: $ssb-green-3;
  }

  &.landing-page-banner {
    min-height: 70vh;

    .container {
      min-height: 70vh;
      display: flex;
      align-items: stretch;
    }

    .col-12 {
      padding: 0;
    }

    .logo {
      padding-top: 4rem;
      width: 20rem;
    }

    .landing-page-banner-title {
      display: flex;
      justify-content: center;

      h1 {
        font-size: 4rem;
      }
    }

    @include media-breakpoint-down(sm) {
      min-height: 60vh;

      .container {
        min-height: 60vh;
      }

      .col-12 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .logo {
        width: 15rem;
      }

      .landing-page-banner-title {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }

  @media print {
    min-height: 80px;
    color: #333;

    .container {
      min-height: 80px;
    }
  }

  @include media-breakpoint-down(sm) {
    min-height: 182px;
    padding: 0;

    .container {
      min-height: 182px;
      padding-right: 10px;
      padding-left: 10px;
      h1 {
        font-size: 36px;
      }
    }
  }
}
